import Extent, {getArea, containsCoordinate} from 'ol/extent';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import Map from 'ol/Map';
import MousePosition from 'ol/control/MousePosition';
import OSM from 'ol/source/OSM';
import Pixel from 'ol/pixel';
import Overlay from 'ol/Overlay';
import LayerGroup from 'ol/layer/Group';
import BaseLayer from 'ol/layer/Base';
import TileLayer from 'ol/layer/Tile';
import Geolocation from 'ol/Geolocation';
import TileWMS from 'ol/source/TileWMS';
import View from 'ol/View';
import {transformExtent} from 'ol/proj';
import WMSServerType from 'ol/source/WMSServerType';
import {WFS, GeoJSON} from 'ol/format';
import Positioning from 'ol/Overlay';
import Feature from 'ol/Feature';
import Source from 'ol/source/Source';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import {Text, Style, Circle, RegularShape, Stroke, Fill} from 'ol/style';
import {StyleFunction, StyleLike} from 'ol/style/Style';
import {Circle as GeomCircle, Point} from 'ol/geom';
import {Coordinate, format} from 'ol/coordinate';
import XYZ from 'ol/source/XYZ';
import {WriteGetFeatureOptions} from 'ol/format/WFS';
import {defaults as defaultControls, Control} from 'ol/control';
import {DEVICE_PIXEL_RATIO} from 'ol/has';
import {and as andFilter, or as orFilter, like as likeFilter, equalTo as equalToFilter} from 'ol/format/filter';
import Vue from 'vue';
import VueI18n from 'vue-i18n'
import queryString from 'query-string';
import WebFont from 'webfontloader';

import {WIKIS, fetchPageSummary} from './wikipedia';
import {isMobile, mod, randomInt, normalizeExtent} from './etc';
import {MESSAGES} from './i18n';

import Buefy from 'buefy';

Vue.use(Buefy);
Vue.use(VueI18n);

var WIKICODE = 'enwiki';

enum LayerType {
  Category = 0,
  Search = 1,
  Other = 2
}

interface SearchParams {
  query: string,
  bbox: Extent.Extent | null
}

type Category = string;

class LayerData {
  title: string;
  param: SearchParams | Category | null = null;
  pinned: boolean = false;
  type: LayerType;
  layers: Array<BaseLayer> = [];
  vectorSource: VectorSource | null = null;
  showFeatures: boolean = false;

  getVisible(): boolean {
    if (this.layers.length > 0) {
      return this.layers[0].getVisible();
    }
    return true;
  };

  setVisible(isVis: boolean) {
    this.layers.forEach((layer: BaseLayer) => layer.setVisible(isVis));
    writeQueryString();
  };
};

// VUE
Vue.component('search-bar', {
  template: `
      <div>
        <b-field>
          <b-input @keydown.native.enter="search()"
                   :placeholder="$t('search.placeholder')"
                   type="search"
                   v-model="query" expanded></b-input>
          <p class="control">
              <b-button @click="search()" class="is-primary is-light">
                {{ $t('search.title') }}
              </b-button>
          </p>
        </b-field>
        <b-field>
          <b-checkbox v-model="restrictBbox">{{ $t('search.restrict') }}</b-checkbox>
        </b-field>
      </div>
    `,
  data: function() { return { query: '', restrictBbox: false } },
  methods: {
    search: function() {
      let bbox = null;
      if (this.restrictBbox) {
        bbox = getExtent();
      }
      if (this.query.trim() != '') {
        addSearchLayer(this.query, bbox, false);
      }
      this.query = '';
    }
  }
});

Vue.component('layer-item', {
  props: ['data'],
  template: `
      <div class="panel-block is-block" :class="!data.pinned && 'unpinned'">
        <nav class="level is-mobile is-marginless layer-item-title">
          <div class="level-left flex-shrink">
            <div class="level-item is-size-5">
              <div v-if="data.pinned">
                <b-icon class="is-marginless" icon="pin"></b-icon>
              </div>
              <div v-else>
                <b-tooltip position="is-right" :label="$t('layer.pin')">
                  <a href="javascript:void(0)"
                     v-on:click="$emit('pin-layer')">
                    <b-icon type=""
                            class="is-marginless"
                            icon="pin-off"></b-icon>
                  </a>
                </b-tooltip>
              </div>
            </div>

            <div class="level-item">
              <span class="tag is-warning"
                    v-if="data.type == 0">{{ $t("layer.category") }}</span>
              <span class="tag is-warning"
                    v-if="data.type == 1">{{ $t("layer.search") }}</span>
              <span class="tag is-warning"
                    v-if="data.type == 2">{{ $t("layer.other") }}</span>
            </div>

            <div class="level-item"
                 v-if="data.type == 1 && data.param.bbox != null">
              <b-tag type="is-info"><b-icon icon="map-search" size="is-small"></b-icon>&nbsp;&nbsp;{{ bboxString }}</b-tag>
            </div>

            <div class="level-item"></div>
          </div>
          <div class="level-right">
            <div class="level-item"><span class="tag is-success">
              <div v-if="data.vectorSource == null">
                <b-icon icon="loading" size="is-small" custom-class="mdi-spin"></b-icon>
              </div>
              <div v-else>
                {{ featureCount }}
              </div>
            </span></div>
          </div>
        </nav>
        <nav class="level is-mobile is-marginless layer-item-title">
            <div class="level-item layer-title flex-shrink ellipsis">
              {{ data.title }}
            </div>
        </nav>
        <nav class="level is-mobile is-marginless">
          <div class="level-left">
            <div class="level-item">
              <b-field>

                <p class="control">
                  <b-tooltip position="is-right" :label="$t('layer.delete')">
                    <b-button type="is-danger" v-on:click="$emit('remove-layer', data)">
                      <b-icon icon="delete"></b-icon>
                    </b-button>
                  </b-tooltip>
                </p>
              </b-field>
            </div>

            <div class="level-item">
              <b-field grouped>

                <p class="control">
                  <b-tooltip :label="$t('layer.browse')">
                    <b-button v-on:click="browse()"
                              :disabled="featureList.length == 0">
                      <b-icon icon="book-open-page-variant"></b-icon>
                    </b-button>
                  </b-tooltip>
                </p>

                <p class="control">
                  <b-tooltip :label="$t('layer.random')">
                    <b-button v-on:click="showRandom()"
                              :disabled="featureList.length == 0">
                      <b-icon icon="dice-multiple"></b-icon>
                    </b-button>
                  </b-tooltip>
                </p>

                <p class="control">
                  <b-tooltip :label="$t('layer.zoom')">
                    <b-button v-on:click="zoom()"
                              :disabled="featureList.length == 0">
                      <b-icon icon="magnify-plus"></b-icon>
                    </b-button>
                  </b-tooltip>
                </p>

                <p class="control">
                  <b-tooltip :label="$t('layer.visible')">
                    <b-button v-on:click="visible = !visible"
                              class="is-primary"
                              :class="visible && 'is-light'"
                              :disabled="featureList.length == 0">
                      <b-icon icon="eye"></b-icon>
                    </b-button>
                  </b-tooltip>
                </p>

              </b-field>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item collapse-link"
                 :class="featureList.length <= 0 && 'is-invisible'">
              <a @click="data.showFeatures = !data.showFeatures">
                <b-icon :icon="!data.showFeatures ? 'menu-right' : 'menu-down'"></b-icon>
              </a>
            </div>
          </div>
        </nav>
        <b-collapse :open.sync="data.showFeatures">
          <div class="layer-details content rtl">
          <ul>
            <li v-for="(item, index) in featureList"
                v-bind:key="item.get('title')"
                class="mdi mdi-map-marker">
              <a href="javascript:void(0)"
                 v-on:click="showFeature(index)">{{ item.get('title') }}</a>
            </li>
          </ul>
          </div>
        </b-collapse>
      </div>
      `,
  computed: {
    featureCount: function(): string {
      // TODO: this logic is stinky
      if (this.data.vectorSource != null) {
        if (this.data.vectorSource.getFeatures().length > 100) {
          return '100+';
        }
        return String(this.data.vectorSource.getFeatures().length);
      } else {
        // TODO: probably loading, should have a loading animation instead
        return '...';
      }
    },
    featureList: function(): Array<Feature> {
      if (this.data.vectorSource != null) {
        return this.data.vectorSource.getFeatures()
      }
      return [];
    },
    visible: {
      get: function(): boolean { return !this.data.getVisible(); },
      set: function(isVis: boolean) { this.data.setVisible(!isVis); },
    },
    bboxString() {
      // TODO: this is dangerous
      let template = '{x} {y}';

      let extent = transformExtent(this.data.param.bbox, 'EPSG:3857', 'EPSG:4326');
      return format(extent.slice(0, 2), template, 2) + ', ' +
             format(extent.slice(2, 4), template, 2);
    }
  },
  methods: {
    zoom: function() {
      if (this.data.vectorSource != null) {
        map.getView().fit(this.data.vectorSource.getExtent());
      }
      if (isMobile()) {
        controlVue.toggleVisible();
      }
    },
    showFeature: function(index: number) {
      showFeatureInList(this.featureList, index);
      wikiCardListData.title = this.data.title;

      if (isMobile()) {
        controlVue.toggleVisible();
      }
    },
    showRandom() {
      this.showFeature(randomInt(0, this.featureList.length));
    },
    browse() {
      this.showFeature(0);
    }
  }
})

var wikiCardListData = {
  index: Number(1),
  values: Array<{wiki: any | null, feature: Feature}>(),
  jump: Boolean(true),
  title: String(),
};

// TODO: rename this
function showFeatureInList(features: Array<Feature>, index: number) {
  let feature = features[index];
  let coordinate = feature.getGeometry().getClosestPoint([0.0, 0.0]);
  showFeatures(features);
  centerOverlayOn(coordinate);

  wikiCardListData.jump = true;
  wikiCardListData.index = index + 1;
  setTarget(undefined, 0);
}

Vue.component('wiki-card-list', {
  template: `
      <div class="panel article-list is-flex">
        <div class="panel-heading article-list-controls">
          <div class="level is-mobile">
            <div class="level-left flex-shrink">
              <a class="delete is-medium level-item" v-on:click="close()"></a>
              <p class="wiki-card-title level-item flex-shrink">
                <!-- TODO: something better -->
                <span v-if="title == 'Search Area'">
                  {{ $t("wikicard.search") }}
                </span>
                <span v-else>{{ title }}</span>
              </p>
            </div>
            <div class="level-right">
              <b-pagination :class="values.length <= 0 && 'is-invisible'"
                            class="level-item"
                v-on:change="change"
                :total="values.length"
                :current.sync="index"
                :simple="true"
                :per-page="1">
              </b-pagination>
            </div>
          </div>
        </div>
        <div class="wiki-card-content pane-block is-marginless">
          <progress v-if="currentCard == null || currentCard.wiki == null"
                    class="progress is-large is-primary query-progress"
                    max="100"></progress>
          <wiki-card v-if="currentCard != null && currentCard.wiki != null"
                     v-bind:data="currentCard"
                     @add-cat="addCat">
          </wiki-card>
        </div>
      </div>
    `,
  data: function() { return wikiCardListData; },
  computed: {
    // TODO: why am i using an external var?
    currentCard: function() {
      if (wikiCardListData.values.length > 0) {
        let data = wikiCardListData.values[wikiCardListData.index - 1];
        if (data.wiki == null) {
          fetchPageSummary(WIKICODE, data.feature.get('title'))
            .then(wiki => data.wiki = wiki);
        }
        return data;
      }
      return null;
    },
  },
  methods: {
    close: function() {
      setOverlayPosition(undefined);
      targetLayer.getSource().clear();
    },
    change(index: number) {
      if (this.jump) {
        let feature = this.values[index-1].feature;
        let coordinate = feature.getGeometry().getClosestPoint([0.0, 0.0]);
        centerOverlayOn(coordinate);
      }
    },
    addCat: function(cat: string, title: string) {
      // set to zero to force loading indicator
      this.values = [];

      addCategoryLayer(cat, false)
      .then(features => {
        let index = features.findIndex(item => item.get('title') == title);
        // TODO: log err message here
        if (index < 0) { index = 0; }
        showFeatureInList(features, index);
        this.title = cat;
      });
    }
  }
});

// Vue Roots
const i18n = new VueI18n({
  locale: WIKIS[WIKICODE][1],
  fallbackLocale: 'en',
  messages: MESSAGES
});

var controlVue = new Vue({
  i18n,
  el: '#root-grid',
  // data: function() { return { baseLayer: 'map' } },
  data: {
    values: Array<LayerData>(),
    baseLayer: String('map'),
    articleLayer: new LayerData(),
    wikicode: String('enwiki'),
    language: String('English'),
    visible: Boolean(true),
    overflow: Boolean(false),
    tmpLayer: null as LayerData|null,
  },
  methods: {
    updateOverflow: function() {
      if (isMobile()) return;

      let sideUnit = document.getElementById('side-unit');
      if (sideUnit != null) {
        this.overflow = sideUnit.scrollHeight > sideUnit.clientHeight;
      }
    },
    addLayer: function(newLayer: LayerData) {
      // don't duplicate layers
      if (this.values.find(layer => layer.title == newLayer.title) ||
          (this.tmpLayer != null && this.tmpLayer.title == newLayer.title)) {
        return;
      }

      // add to map
      newLayer.layers.forEach(layer => map.addLayer(layer));

      if (!newLayer.pinned) {
        if (this.tmpLayer) { this.removeLayer(this.tmpLayer); }
        this.tmpLayer = newLayer;
      } else {
        this.values.push(newLayer);
      }

      writeQueryString();
    },
    pinLayer: function() {
      this.tmpLayer.pinned = true;
      this.values.unshift(this.tmpLayer);
      this.tmpLayer = null;

      writeQueryString();
    },
    removeLayer: function(layer: LayerData) {
      // remove from map
      layer.layers.forEach((layer: BaseLayer) => map.removeLayer(layer));

      if (!layer.pinned) {
        this.tmpLayer = null;
      } else {
        this.values.splice(controlVue.values.indexOf(layer), 1);
      }

      writeQueryString();
    },
    toggleVisible() {
      this.visible = !this.visible;
      if (isMobile()) {

        // TODO: create a vue component for this
        if (this.visible) {
          window.history.pushState('controls', '');
          window.onpopstate = (event: Event) => this.toggleVisible();
        } else {
          window.onpopstate = writeQueryString;
          // TODO: this is hacky
          if (window.history.state == 'controls') {
            window.history.back();
          }
          writeQueryString();
        }

      }
    }
  },
  computed: {
    articlesVisible: {
      get(): boolean {
        return !this.articleLayer.getVisible();
      },
      set(value: boolean) {
        this.articleLayer.setVisible(!value);
      }
    }
  },
  watch: {
    wikicode: function(newValue, oldValue) {
      WIKICODE = newValue;
      this.language = WIKIS[WIKICODE][0];

      // TODO: this is too hacky
      this.tmpLayer = null;
      writeQueryString();
      readQueryString();
    },
    baseLayer: function(newLayer: String, oldLayer: String) {
      osmTileLayer.setVisible(false)
      esriTileLayer.setVisible(false)

      switch (newLayer) {
        case 'map':
          osmTileLayer.setVisible(true)
          break;
        case 'satellite':
          esriTileLayer.setVisible(true)
          break;
        case 'none':
          break;
        default:
          console.log(`Unknown layer "${newLayer}"`)
      }

      writeQueryString();
    },
    values() { Vue.nextTick(this.updateOverflow); }
  }
});

const controlObserver = new MutationObserver(controlVue.updateOverflow);
controlObserver.observe(document.getElementById('root-grid'),
                        { subtree: true, attributes: true, childList: true });

Vue.component('wiki-card', {
  props: ['data'],
  template: `
      <div class="card is-marginless">
        <div v-if="data.wiki['thumbnail']" class="card-image">
          <figure class="image">
            <img class="wiki-card-image" :src="thumbnail"></img>
          </figure>
        </div>
        <div class="card-content">
          <div class="content rtl">
            <a :href="pageLink" target="_blank">
              <u><h2 v-html="title"></h2></u>
            </a>
            <span v-html="body"></span>
            <ul>
              <li v-for="cat in categories" class="mdi mdi-map-search">
                <a v-on:click="$emit('add-cat', cat, data.feature.get('title'))"
                   href="javascript:void(0)">{{ cat }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    `,
  computed: {
    pageLink: function(): string {
      if ('content_urls' in this.data.wiki) {
        return this.data.wiki['content_urls']['desktop']['page'];
      }
      return 'https://' + WIKIS[WIKICODE][1] + '.wikipedia.org/wiki/' + this.data.title;
    },
    title: function(): string {
      if ('displaytitle' in this.data.wiki) {
        return this.data.wiki['displaytitle']
      }
      return this.data.title;
    },
    thumbnail: function(): string {
      if ('thumbnail' in this.data.wiki) {
        return this.data.wiki['thumbnail']['source']
      }
      return '';
    },
    body: function(): string {
      if ('extract_html' in this.data.wiki) {
        return this.data.wiki['extract_html']
      }
      return '';
    },
    categories: function(): Array<string> {
      return (this.data.feature.get('categories') as string).split('#').filter(str => str != '' );
    }
  },
  methods: {
  }
})

var wikiCardPopup = new Vue({
  i18n,
  el: '#popup',
  data: {
    above: true,
  }
});

var wikiCardOverlay = new Vue({
  i18n,
  el: '#wiki-card-overlay',
});

var loadingIndicator = new Vue({
  i18n,
  el: '#loading-indicator',
  data: {
    loading: 0,
  },
  methods: {
    start() { this.loading += 1; },
    end() { this.loading -= 1; },
    error() { this.loading -= 1; },
    register(source: Source) {
      source.on('tileloadstart', loadingIndicator.start);
      source.on('tileloadend', loadingIndicator.end);
      source.on('tileloaderror', loadingIndicator.error);
    }
  }
})

async function writeQueryString() {
  // normalize center location
  let center = map.getView().getCenter();
  let bbox = normalizeExtent([center[0], center[1], center[0], center[1]]);

  // TODO: is it best to filter on visibility?
  let query = {
    wiki: WIKICODE,
    lat: bbox[0].toFixed(4),
    lon: bbox[1].toFixed(4),
    zoom: Math.floor(map.getView().getZoom()),
    base: controlVue.baseLayer,
    showAll: controlVue.articleLayer.getVisible(),
    search: controlVue.values
      .filter(layerData => layerData.type == LayerType.Search &&
                           layerData.layers[0].getVisible() &&
                           layerData.pinned)
      .map(layerData => JSON.stringify(layerData.param as SearchParams)),
    category: controlVue.values
      .filter(layerData => layerData.type == LayerType.Category &&
                           layerData.layers[0].getVisible() &&
                           layerData.pinned)
      .map(layerData => layerData.param as Category)
  };

  let qs = queryString.stringify(query, {arrayFormat: 'bracket'})
    .replace(/\[/g, '%5B')
    .replace(/\]/g, '%5D');
  window.history.replaceState(window.history.state, '', location.pathname + '?'
                              + qs);
}

async function readQueryString() {
  const parsed = queryString.parse(location.search, {parseNumbers: true, parseBooleans: true, arrayFormat: 'bracket'});
  if ('wiki' in parsed && typeof(parsed.wiki) == 'string' &&
      parsed.wiki in WIKIS) {
    WIKICODE = (parsed.wiki);
    i18n.locale = WIKIS[WIKICODE][1];
    controlVue.wikicode = WIKICODE;
  }

  // Check for RTL languages
  if (WIKICODE == 'arwiki') {
    document.body.classList.add('rtl-enable');
  } else {
    document.body.classList.remove('rtl-enable');
  }

  // Load Noto fonts if necesarry
  if (WIKICODE == 'zhwiki') {
    // TODO: HK or SC or TC?
    WebFont.load({ google: { families: ['Noto Sans HK:400,700'] } });
  } else if (WIKICODE == 'jawiki') {
    WebFont.load({ google: { families: ['Noto Sans JP:400,700'] } });
  }

  initLayers();

  if ('lat' in parsed && typeof(parsed.lat) == 'number' &&
      'lon' in parsed && typeof(parsed.lon) == 'number') {
    map.getView().setCenter([parsed.lat, parsed.lon]);
  }
  if ('zoom' in parsed && typeof(parsed.zoom) == 'number') {
    map.getView().setZoom(parsed.zoom);
  }
  if ('smooth' in parsed && typeof(parsed.smooth) == 'boolean') {
    map.getView().setConstrainResolution(!parsed.smooth);
  }
  if ('base' in parsed && typeof(parsed.base) == 'string' &&
       ['map', 'satellite', 'none'].includes(parsed.base)) {
    controlVue.baseLayer = parsed.base;
  }
  if ('showAll' in parsed && typeof(parsed.showAll) == 'boolean') {
    controlVue.articleLayer.setVisible(parsed.showAll);
  }
  if ('search' in parsed && Array.isArray(parsed.search)) {
    parsed.search.forEach(term => {
      if (typeof(term) == 'string') {
        // FIXME: this is insecure
        const param = JSON.parse(term) as SearchParams;
        addSearchLayer(param.query, param.bbox, true);
      }
    });
  }
  if ('category' in parsed && Array.isArray(parsed.category)) {
    parsed.category.forEach(term => {
      if (typeof(term) == 'string') {
        addCategoryLayer(term, true);
      }
    });
  }
}

function getExtent(): Extent.Extent {
  let bbox = map.getView().calculateExtent();
  return normalizeExtent(bbox);
}

function fetchFeatures(options: WriteGetFeatureOptions) {
  loadingIndicator.start();

  if (options.bbox != null) {
    options.bbox = transformExtent(options.bbox, 'EPSG:3857', 'EPSG:4326');
  }

  var featureRequest = new WFS().writeGetFeature(options);
  return fetch('/ows', {
    method: 'POST',
    body: new XMLSerializer().serializeToString(featureRequest)
  }).then(function(response) {
    return response.json();
  }).then(function(json) {
    return new GeoJSON({ featureProjection: 'EPSG:3857' }).readFeatures(json);
  })
  .finally(loadingIndicator.end);
}

/*
function clickFeatures(bbox: Extent.Extent, layerName: string) {
  // generate a GetFeature request
  var featureRequest = new WFS().writeGetFeature({
      srsName: 'EPSG:4326',
    featureNS: 'http://www.tinyows.org/"',
    featurePrefix: 'wikimap',
    featureTypes: ['wikimap:' + layerName],
    geometryName: 'location',
    bbox: bbox,
    maxFeatures: 20,
    outputFormat: 'application/json'
  });

  return fetch('/ows', {
    method: 'POST',
    body: new XMLSerializer().serializeToString(featureRequest)
  }).then(function(response) {
    return response.json();
  }).then(function(json) {
    return new GeoJSON().readFeatures(json);
  });
}
*/

function showFeatures(features: Array<Feature>) {
  // reset this to be safe
  wikiCardListData.jump = false;

  let entries = new Set<String>();
  wikiCardListData.values = [];
  wikiCardListData.index = 1;

  features.forEach(feature => {
    let title = feature.get('title');

    if (!entries.has(title)) {
      entries.add(title);

      wikiCardListData.values.push({wiki: null, feature: feature});
    }
  });
}

function getMapViewportCenter(): Pixel.Pixel {
    let mapRect = document.getElementById('map').getBoundingClientRect();

    let x_off = Math.floor(mapRect.right / 2);
    let y_off = Math.floor(mapRect.bottom / 2);
    return [x_off, y_off];
}

function centerOverlayOn(coordinate: Coordinate) {
  if (isMobile()) {
    // open overlay to ensure mapRect has already been shrunk
    setOverlayPosition(coordinate);
    map.getView().centerOn(coordinate, map.getSize(), getMapViewportCenter());
    return;
  }

  let extent = map.getView().calculateExtent(map.getSize());

  if (overlay.getPosition() != undefined &&
      containsCoordinate(extent, overlay.getPosition())) {
    map.getView().centerOn(coordinate, map.getSize(),
                           map.getPixelFromCoordinate(overlay.getPosition()));
    setOverlayPosition(coordinate);
  } else {
    map.getView().setCenter(coordinate);
    setOverlayPosition(coordinate);
  }
}

async function featureEvent(evt: MapBrowserEvent) {
  var pixelShift = isMobile() ? 20 : 10;

  let coor = map.getCoordinateFromPixel(evt.pixel);
  let res = map.getView().getResolutionForZoom(map.getView().getZoom());
  let bottomLeft = coor.map((x) => x - res * pixelShift);
  let topRight = coor.map((x) => x + res * pixelShift);
  let extent = bottomLeft.concat(topRight);

  let bbox: Extent.Extent = [extent[0], extent[1], extent[2], extent[3]];
  bbox = normalizeExtent(bbox);

  setTarget(coor, res * pixelShift);

  // immediately show loading popup
  wikiCardListData.values = [];
  setOverlayPosition(evt.coordinate);
  overlay.panIntoView();

  let featureList = new Array<Feature>();

  if (controlVue.articleLayer.getVisible()) {
    let wfsOptions = {
      srsName: 'EPSG:4326',
      featureNS: 'https://wikimap.wiki/',
      featurePrefix: 'wikimap',
      featureTypes: ['wikimap:' + WIKICODE],
      geometryName: 'location',
      bbox: bbox,
      filter: equalToFilter('globe', 'earth', false),
      maxFeatures: 20,
      outputFormat: 'application/json'
    }
    await fetchFeatures(wfsOptions)
      .then(features => featureList.push(...features));
  }

  // TODO: add controlVue get function for all layer data
  controlVue.values.forEach(layerData => {
    if (layerData.vectorSource != null && layerData.layers[0].getVisible()) {
      featureList.push(...layerData.vectorSource.getFeaturesInExtent(bbox));
    }
  });
  if (controlVue.tmpLayer != null &&
      controlVue.tmpLayer.vectorSource != null &&
      controlVue.tmpLayer.layers[0].getVisible()) {
    featureList.push(...controlVue.tmpLayer.vectorSource.getFeaturesInExtent(bbox));
  }

  if (featureList.length > 0) {
    showFeatures(featureList);
    setOverlayPosition(evt.coordinate);
  } else {
    setOverlayPosition(undefined);
  }

  wikiCardListData.title = "Search Area";
};

var overlay = new Overlay({
  element: document.getElementById('popup')
});

/************/
let  geoZoom = false;

class GeolocationControl extends Control {
  constructor(opt_options: any | null) {
    const options = opt_options || {};

    const button = document.createElement('button');
    button.innerHTML = '<i class="is-marginless mdi mdi-crosshairs-gps"></i>';

    const element = document.createElement('div');
    element.className = 'ol-gps ol-unselectable ol-control';
    element.appendChild(button);

    super({
      element: element,
      target: options.target,
    });

    button.addEventListener('click', this.handleClick.bind(this), false);
  }

  handleClick() {
    geolocation.setTracking(true);
    geoZoom = true;
    updateGeolocation();
  }
}

const positionFeature = new Feature();
positionFeature.setStyle(
  new Style({
    image: new Circle({
      radius: 6,
      fill: new Fill({
        color: '#3399CC',
      }),
      stroke: new Stroke({
        color: '#fff',
        width: 2,
      }),
    }),
  }),
);

function updateGeolocation() {
  const coord = geolocation.getPosition();
  positionFeature.setGeometry(coord ? new Point(coord) : null);
  console.log(coord, geoZoom);
  if (geoZoom && coord != null) {
    map.getView().setCenter(geolocation.getPosition());
    map.getView().setZoom(12);
    geoZoom = false;
  }
}
/************/

var map = new Map({
  layers: [],
  target: 'map',
  view: new View({
    center: [0, 0],
    zoom: 2,
    maxZoom: 18,
    enableRotation: false,
    constrainResolution: true,
  }),
  controls: defaultControls(
    { attributionOptions: { collapsible: true } }
  ).extend([new GeolocationControl()]),
  overlays: [overlay]
});

const geolocation = new Geolocation({
  trackingOptions: {
    enableHighAccuracy: true,
  },
  projection: map.getView().getProjection(),
});

geolocation.on('change:position', () => {
  updateGeolocation();
});

setOverlayPosition(undefined);
document.getElementById('popup').style.visibility = 'visible';

var osmTileLayer: TileLayer | null = null;
var esriTileLayer: TileLayer | null = null;
// var articlesLayerData: LayerData | null = null;
var pinnedLayerData: LayerData | null = null;
var targetLayer: VectorLayer;

// TODO: this is mega hacky
function initLayers() {
  // Remove all existing layers
  map.setLayerGroup(new LayerGroup());

  // Clear out control vue
  controlVue.values = [];

  // Hide overlay
  setOverlayPosition(undefined);

  // Add made-by-me layer
  {
    let vectorSource = new VectorSource();
    vectorSource.setAttributions(`
      <p class="has-text-weight-bold is-size-5">
        Wikimap is made by
        <a target="_blank" href="https://waffle.tech/louis/">Louis Jencka</a>
      </p>
      <span style="float:left;">Data updated weekly</span>
      <p class="has-text-weight-bold">
        <a target="_blank" href="https://gitlab.com/nocylah/wikimap/">
          <i class="mdi mdi-git"></i>
          Source code
        </a>
      </p>
      <div class="attr-separator"></div>
    `);
    map.addLayer(new VectorLayer({source: vectorSource}));
  }

  osmTileLayer = new TileLayer({
      source: new OSM({ attributions: 'Base tiles © <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors' }),
      opacity: 1.0
  });
  loadingIndicator.register(osmTileLayer.getSource());
  map.addLayer(osmTileLayer);

  esriTileLayer = new TileLayer({
    source: new XYZ({
      attributions: '<p>Base tiles © <a href="https://services.arcgisonline.com/ArcGIS/' +
          'rest/services/World_Topo_Map/MapServer">ArcGIS</a></p>',
      url: 'https://server.arcgisonline.com/ArcGIS/rest/services/' +
          'World_Imagery/MapServer/tile/{z}/{y}/{x}'
    })
  })
  loadingIndicator.register(esriTileLayer.getSource());
  esriTileLayer.setVisible(false);
  map.addLayer(esriTileLayer);

  let mapnik = new TileLayer({
    source: new XYZ({
      attributions: '<p>Data derived from <a href="https://www.wikidata.org/wiki/Wikidata:Data_access">Wikidata</a> & <a href="https://dumps.wikimedia.org/">Wikipedia</a></p>',
      url: '/tiles/' + WIKICODE + '/{z}/{x}/{y}.png',
      tilePixelRatio: DEVICE_PIXEL_RATIO > 1 ? 2 : 1
    })
  })
  loadingIndicator.register(mapnik.getSource());
  mapnik.setVisible(true);

  let layerData = new LayerData();
  layerData.title = 'All Articles';
  layerData.type = LayerType.Other;
  layerData.layers = [mapnik];

  controlVue.articleLayer = layerData;

  map.addLayer(mapnik);

  {
    let vectorSource = new VectorSource();
    targetLayer = new VectorLayer({
      source: vectorSource,
      style: new Style({
        stroke: new Stroke({
          color: '#000000',
          width: 2,
          lineDash: [1, 5],
        }),
        fill: new Fill({
          color: 'rgba(255, 255, 0, 0.5)',
        }),
      }),
    });
    map.addLayer(targetLayer);
  }

  let geoDot = new VectorLayer({
    source: new VectorSource({
      features: [positionFeature],
    }),
  });
  map.addLayer(geoDot);
}

function setTarget(coordinate: Coordinate, size: number) {
  let source = targetLayer.getSource();
  source.clear();
  if (coordinate != undefined) {
    source.addFeature(new Feature(new GeomCircle(coordinate, size)));
  }
}

function addCategoryLayer(category: string, pinned: boolean) {
  let options = {
      srsName: 'EPSG:4326',
      featureNS: 'https://wikimap.wiki/',
      featurePrefix: 'wikimap',
      featureTypes: ['wikimap:' + WIKICODE],
      geometryName: 'location',
      outputFormat: 'application/json',
      // FIXME FIXME FIXME this is a tinyows bug :( :( :(
      filter: andFilter(equalToFilter('globe', 'earth', false),
                        likeFilter('categories', '*#' + category.replace(/[()]/g, '.') + '#*'))
    }
    return addWfsLayer(category, LayerType.Category, category, pinned, options);
}

function addSearchLayer(query: string, bbox: Extent.Extent|null, pinned: boolean) {
  let options = {
      srsName: 'EPSG:4326',
      featureNS: 'https://wikimap.wiki/',
      featurePrefix: 'wikimap',
      featureTypes: ['wikimap:' + WIKICODE],
      geometryName: 'location',
      outputFormat: 'application/json',
      bbox: bbox,
      // FIXME FIXME FIXME this is a tinyows bug :( :( :(
      filter: andFilter(equalToFilter('globe', 'earth', false),
                        likeFilter('title', '*' + query.replace(/[()]/g, '.') + '*',
                                   '*', '?', '!', false)),
    }
    let param = { query: query, bbox: bbox };
    return addWfsLayer('"' + query + '"', LayerType.Search, param, pinned, options);
}

let _vectorLabelStyle_stroke = new Stroke({ color: '#ffffff', width: 4 });

let vectorLabelStyle: StyleFunction = (feature, resolution) => {
  return new Style({
    text: new Text({
      font: 'bold 14px "Noto Sans", "DejaVu Sans", "Open Sans", "sans-serif"',
      textAlign: 'left',
      offsetX: 10,
      placement: 'point',
      text: feature.get('title'),
      /*
      fill: new Fill({
        color: '#fdb863'
      }),
      */
      stroke: _vectorLabelStyle_stroke,
    })
  });
}

let vectorPointStyle = [
  new Style({
    image: new RegularShape({
      points: 3,
      radius: 8,
      stroke: new Stroke({
        color: '#ffff00',
        width: 3
      }),
    })
  }),
  new Style({
    image: new RegularShape({
      points: 3,
      radius: 8,
      stroke: new Stroke({
        color: '#000000',
        width: 1
      }),
    })
  })
];

function addWfsLayer(title: string, layerType: LayerType, param: SearchParams|Category|null, pinned: boolean, options: WriteGetFeatureOptions) {

  let vectorSource = new VectorSource();
  let labelLayer = new VectorLayer({
    source: vectorSource,
    style: vectorLabelStyle,
    declutter: true
  });

  let pointLayer = new VectorLayer({
    source: vectorSource,
    style: vectorPointStyle,
    declutter: false
  });

  // TODO: this doesn't work, as we're not loading tiles...
  loadingIndicator.register(vectorSource);

  let newLayer = new LayerData();
  newLayer.title = title;
  newLayer.type = layerType;
  newLayer.param = param;
  newLayer.layers = [pointLayer, labelLayer];
  newLayer.vectorSource = null;
  newLayer.showFeatures = false;
  newLayer.pinned = pinned;

  // generate a GetFeature request
  let features = fetchFeatures(options)
    .then(features => {
      // TODO: move this to newLayer function for better division
      newLayer.vectorSource = vectorSource;
      vectorSource.addFeatures(features);
      return features;
    });

  controlVue.addLayer(newLayer);

  writeQueryString();

  return features;
}

function setOverlayPosition(coord: Coordinate|undefined) {
  // set the popup marker position for best visibility
  if (coord) {
    let center = map.getView().getCenter();
    if (coord[1] > center[1]) {
      overlay.setOffset([-40, 20]);
      overlay.setPositioning('top-left');
      wikiCardPopup.above = true;
    } else {
      overlay.setOffset([-40, -20]);
      overlay.setPositioning('bottom-left');
      wikiCardPopup.above = false;
    }
  }

  overlay.setPosition(coord);

  let wikiCardOverlay = document.getElementById('wiki-card-overlay');

  if (coord == undefined) {
    const centerCoord = map.getView().getCenter();
    const centerPixel = getMapViewportCenter();

    wikiCardOverlay.style.visibility = 'hidden';
    wikiCardOverlay.style.display = 'none';

    map.updateSize();

    if (isMobile()) {
      map.getView().centerOn(centerCoord, map.getSize(), centerPixel);
    }
  } else {
    wikiCardOverlay.style.visibility = 'visible';
    wikiCardOverlay.style.display = 'initial';

    const center = map.getCoordinateFromPixel(getMapViewportCenter());
    map.updateSize();

    if (isMobile()) {
      map.getView().setCenter(center);
    }
  }
}

map.on('singleclick', featureEvent);
// map.on('click', featureEvent);
map.on('moveend', writeQueryString);

window.addEventListener('resize', controlVue.updateOverflow);

if (isMobile()) {
  // hide controls by default on mobile
  controlVue.visible = false;
}

// FIXME: this is hacky
readQueryString();
