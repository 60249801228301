export var WIKIS: Record<string, [string, string]> = {
  'arwiki': ['العربية', 'ar'],
  'cawiki': ['Català', 'ca'],
  'dewiki': ['Deutsch', 'de'],
  'enwiki': ['English', 'en'],
  'eswiki': ['Español', 'es'],
  'frwiki': ['Français', 'fr'],
  'idwiki': ['Bahasa Indonesia', 'id'],
  'itwiki': ['Italiano', 'it'],
  'jawiki': ['日本語', 'ja'],
  'nlwiki': ['Nederlands', 'nl'],
  'plwiki': ['Polski', 'pl'],
  'ptwiki': ['Português', 'pt'],
  'ruwiki': ['Русский', 'ru'],
  'trwiki': ['Türkçe', 'tr'],
  'zhwiki': ['中文', 'zh']
};

export function fetchPageSummary(wikicode: string, title: string) {
  // TODO: this isn't good
  let safeTitle = title.replace(/\//g, '%2F')
                       .replace(/ /g, '_');
  return fetch('https://' + WIKIS[wikicode][1] +
               '.wikipedia.org/api/rest_v1/page/summary/' + safeTitle +
               '?redirect=true')
  .then((response) => {
    if (response.ok) {
      return response.json()
    }
    return {};
  })
};
