import Extent from 'ol/extent';
import {transformExtent} from 'ol/proj';

export function isMobile(): boolean {
  return window.matchMedia("only screen and (max-width: 1023px)").matches;
}

export function mod(n: number, m: number) {
  return ((n % m) + m) % m;
}

export function randomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
}

export function normalizeExtent(extent: Extent.Extent): Extent.Extent {
  // TODO: something more elegant
  extent = transformExtent(extent, 'EPSG:3857', 'EPSG:4326');
  extent[0] = mod(extent[0] + 180, 360) - 180;
  extent[2] = mod(extent[2] + 180, 360) - 180;
  return transformExtent(extent, 'EPSG:4326', 'EPSG:3857');
}
