import 'ol/ol.css';
import '@mdi/font/css/materialdesignicons.css';
import 'buefy/dist/buefy.css';

import './index.css';

/*
if (!("PointerEvent" in window)) {
	import("@wessberg/pointer-events");
}
*/

import './map.ts';
