export const MESSAGES = {
  en: {
    controls: {
      show: "Show Controls",
      hide: "Hide Controls"
    },
    articles: {
      title: "All Articles",
      tooltip: "Show/hide layer"
    },
    base: {
      title: "Base Map",
      map: "Map",
      satellite: "Satellite",
      none: "None"
    },
    search: {
      title: "Search",
      placeholder: "Search...",
      restrict: "Restrict to visible area"
    },
    layer: {
      title: "Layers",
      pin: "Pin Layer",
      delete: "Delete Layer",
      browse: "Browse Layer",
      random: "Show random article",
      zoom: "Zoom to show whole layer",
      visible: "Show/hide layer",
      category: "Category",
      search: "Search",
      other: "Other"
    },
    wikicard: {
      search: "Search Area"
    }
  },

  es: {
    controls: {
      show: "Mostrar controles",
      hide: "Ocultar controles",
    },
    articles: {
      title: "Todos los artículos",
      tooltip: "Mostrar / ocultar capa",
    },
    base: {
      title: "Mapa base",
      map: "Mapa",
      satellite: "Satélite",
      none: "Ninguna",
    },
    search: {
      title: "Buscar",
      placeholder: "Buscar...",
      restrict: "Restringir al área visible",
    },
    layer: {
      title: "Capas",
      pin: "Capa de alfiler",
      delete: "Eliminar capa",
      browse: "Examinar capa",
      random: "Mostrar artículo aleatorio",
      zoom: "Zoom para mostrar la capa completa",
      visible: "Mostrar / ocultar capa",
      category: "Categoría",
      search: "Buscar",
      other: "Otro",
    },
    wikicard: {
      search: "Área de búsqueda",
    }
  },

  fr: {
    controls: {
      show: "Afficher les contrôles",
      hide: "Masquer les contrôles",
    },
    articles: {
      title: "Tous les articles",
      tooltip: "Afficher / masquer le calque",
    },
    base: {
      title: "Carte de base",
      map: "Carte",
      satellite: "Satellite",
      none: "Aucun",
    },
    search: {
      title: "Chercher",
      placeholder: "Chercher...",
      restrict: "Restreindre à la zone visible",
    },
    layer: {
      title: "Couches",
      pin: "Couche de broche",
      delete: "Supprimer le calque",
      browse: "Parcourir la couche",
      random: "Afficher un article aléatoire",
      zoom: "Zoomez pour afficher la couche entière",
      visible: "Afficher / masquer le calque",
      category: "Catégorie",
      search: "Chercher",
      other: "Autre",
    },
    wikicard: {
      search: "Zone de recherche",
    }
  },

  de: {
    controls: {
      show: "Steuerelemente anzeigen",
      hide: "Steuerelemente ausblenden",
    },
    articles: {
      title: "Alle Artikel",
      tooltip: "Ebene ein- / ausblenden",
    },
    base: {
      title: "Basiskarte",
      map: "Karte",
      satellite: "Satellit",
      none: "Keiner",
    },
    search: {
      title: "Suche",
      placeholder: "Suche...",
      restrict: "Auf sichtbaren Bereich beschränken",
    },
    layer: {
      title: "Lagen",
      pin: "Pin-Schicht",
      delete: "Ebene löschen",
      browse: "Ebene durchsuchen",
      random: "Zufälligen Artikel anzeigen",
      zoom: "Zoomen Sie, um die gesamte Ebene anzuzeigen",
      visible: "Ebene ein- / ausblenden",
      category: "Kategorie",
      search: "Suche",
      other: "Andere",
    },
    wikicard: {
      search: "Suchbereich",
    }
  },

  zh: {
    controls: {
      show: "显示控件",
      hide: "隐藏控件",
    },
    articles: {
      title: "所有文章",
      tooltip: "显示/隐藏图层",
    },
    base: {
      title: "底图",
      map: "地图",
      satellite: "卫星",
      none: "没有",
    },
    search: {
      title: "搜索",
      placeholder: "搜索...",
      restrict: "限制在可见区域",
    },
    layer: {
      title: "层数",
      pin: "引脚层",
      delete: "删除图层",
      browse: "浏览层",
      random: "显示随机文章",
      zoom: "缩放以显示整个图层",
      visible: "显示/隐藏图层",
      category: "类别",
      search: "搜索",
      other: "其他",
    },
    wikicard: {
      search: "搜索区域",
    }
  },

  ru: {
    controls: {
      show: "Показать элементы управления",
      hide: "Скрыть элементы управления",
    },
    articles: {
      title: "Все статьи",
      tooltip: "Показать / скрыть слой",
    },
    base: {
      title: "Базовая карта",
      map: "карта",
      satellite: "спутник",
      none: "Никто",
    },
    search: {
      title: "Поиск",
      placeholder: "Поиск...",
      restrict: "Ограничить видимой областью",
    },
    layer: {
      title: "Слои",
      pin: "Слой булавки",
      delete: "Удалить слой",
      browse: "Обзор слоя",
      random: "Показать случайную статью",
      zoom: "Увеличить, чтобы показать весь слой",
      visible: "Показать / скрыть слой",
      category: "Категория",
      search: "Поиск",
      other: "Другие",
    },
    wikicard: {
      search: "Область поиска",
    }
  },

  pt: {
    controls: {
      show: "Mostrar controles",
      hide: "Ocultar controles",
    },
    articles: {
      title: "Todos os artigos",
      tooltip: "Mostrar / ocultar camada",
    },
    base: {
      title: "Mapa base",
      map: "Mapa",
      satellite: "Satélite",
      none: "Nenhum",
    },
    search: {
      title: "Pesquisa",
      placeholder: "Pesquisa...",
      restrict: "Restringir à área visível",
    },
    layer: {
      title: "Camadas",
      pin: "Camada de pinos",
      delete: "Excluir Camada",
      browse: "Camada de Navegação",
      random: "Mostrar artigo aleatório",
      zoom: "Zoom para mostrar a camada inteira",
      visible: "Mostrar / ocultar camada",
      category: "Categoria",
      search: "Pesquisa",
      other: "De outros",
    },
    wikicard: {
      search: "Área de Pesquisa",
    }
  },

  it: {
    controls: {
      show: "Mostra controlli",
      hide: "Nascondi controlli",
    },
    articles: {
      title: "Tutti gli articoli",
      tooltip: "Mostra / nascondi livello",
    },
    base: {
      title: "Mappa di base",
      map: "Mappa",
      satellite: "Satellitare",
      none: "Nessuna",
    },
    search: {
      title: "Ricerca",
      placeholder: "Ricerca...",
      restrict: "Limita all'area visibile",
    },
    layer: {
      title: "Strati",
      pin: "Livello pin",
      delete: "Elimina livello",
      browse: "Sfoglia livello",
      random: "Mostra articolo casuale",
      zoom: "Zoom per mostrare l'intero livello",
      visible: "Mostra / nascondi livello",
      category: "Categoria",
      search: "Ricerca",
      other: "Altro",
    },
    wikicard: {
      search: "Area di ricerca",
    }
  },

  ar: {
    controls: {
      show: "إظهار الضوابط",
      hide: "إخفاء الضوابط",
    },
    articles: {
      title: "جميع المقالات",
      tooltip: "إظهار / إخفاء الطبقة",
    },
    base: {
      title: "الخريطة الأساسية",
      map: "خريطة",
      satellite: "الأقمار الصناعية",
      none: "لا شيء",
    },
    search: {
      title: "بحث",
      placeholder: "بحث...",
      restrict: "قصر على المنطقة المرئية",
    },
    layer: {
      title: "طبقات",
      pin: "طبقة دبوس",
      delete: "حذف الطبقة",
      browse: "تصفح الطبقة",
      random: "عرض مقال عشوائي",
      zoom: "تكبير لإظهار الطبقة بأكملها",
      visible: "إظهار / إخفاء الطبقة",
      category: "الفئة",
      search: "بحث",
      other: "آخر",
    },
    wikicard: {
      search: "منطقة البحث",
    }
  },

  ja: {
    controls: {
      show: "コントロールを表示",
      hide: "コントロールを非表示",
    },
    articles: {
      title: "すべての記事",
      tooltip: "レイヤーの表示/非表示",
    },
    base: {
      title: "ベースマップ",
      map: "地図",
      satellite: "衛星",
      none: "無し",
    },
    search: {
      title: "探す",
      placeholder: "探す...",
      restrict: "表示領域に制限する",
    },
    layer: {
      title: "レイヤー",
      pin: "ピンレイヤー",
      delete: "レイヤーを削除",
      browse: "レイヤーを参照",
      random: "ランダムな記事を表示する",
      zoom: "ズームしてレイヤー全体を表示",
      visible: "レイヤーの表示/非表示",
      category: "カテゴリー",
      search: "探す",
      other: "その他",
    },
    wikicard: {
      search: "検索エリア",
    }
  },

  tr: {
    controls: {
      show: "Kontrolleri Göster",
      hide: "Kontrolleri Gizle",
    },
    articles: {
      title: "Bütün makaleler",
      tooltip: "Katmanı göster / gizle",
    },
    base: {
      title: "Temel harita",
      map: "Harita",
      satellite: "Uydu",
      none: "Yok",
    },
    search: {
      title: "Arama",
      placeholder: "Arama...",
      restrict: "Görünür alanla sınırla",
    },
    layer: {
      title: "Katmanlar",
      pin: "Pin Katmanı",
      delete: "Katmanı Sil",
      browse: "Katmana Göz At",
      random: "Rastgele makale göster",
      zoom: "Tüm katmanı göstermek için yakınlaştırın",
      visible: "Katmanı göster / gizle",
      category: "Kategori",
      search: "Arama",
      other: "Diğer",
    },
    wikicard: {
      search: "Arama Alanı",
    }
  },

  id: {
    controls: {
      show: "Tampilkan Kontrol",
      hide: "Sembunyikan Kontrol",
    },
    articles: {
      title: "Semua Artikel",
      tooltip: "Tampilkan / sembunyikan lapisan",
    },
    base: {
      title: "Peta Dasar",
      map: "Peta",
      satellite: "Satelit",
      none: "Tidak ada",
    },
    search: {
      title: "Cari",
      placeholder: "Cari...",
      restrict: "Batasi untuk area yang terlihat",
    },
    layer: {
      title: "Lapisan",
      pin: "Lapisan Pin",
      delete: "Hapus Lapisan",
      browse: "Jelajahi Layer",
      random: "Tampilkan artikel acak",
      zoom: "Zoom untuk menampilkan seluruh lapisan",
      visible: "Tampilkan / sembunyikan lapisan",
      category: "Kategori",
      search: "Cari",
      other: "Lain",
    },
    wikicard: {
      search: "Area Pencarian",
    }
  },

  nl: {
    controls: {
      show: "Toon bedieningselementen",
      hide: "Verberg besturingselementen",
    },
    articles: {
      title: "Alle artikelen",
      tooltip: "Laag weergeven / verbergen",
    },
    base: {
      title: "Basiskaart",
      map: "Kaart",
      satellite: "Satelliet",
      none: "Geen",
    },
    search: {
      title: "Zoeken",
      placeholder: "Zoeken...",
      restrict: "Beperken tot zichtbaar gebied",
    },
    layer: {
      title: "Lagen",
      pin: "Laag vastzetten",
      delete: "Laag verwijderen",
      browse: "Blader door laag",
      random: "Laat willekeurig artikel zien",
      zoom: "Zoom in om de hele laag weer te geven",
      visible: "Laag weergeven / verbergen",
      category: "Categorie",
      search: "Zoeken",
      other: "Andere",
    },
    wikicard: {
      search: "Zoekgebied",
    }
  },

  pl: {
    controls: {
      show: "Pokaż kontrolki",
      hide: "Ukryj sterowanie",
    },
    articles: {
      title: "Wszystkie artykuły",
      tooltip: "Pokaż / ukryj warstwę",
    },
    base: {
      title: "Mapa bazy",
      map: "Mapa",
      satellite: "Satelita",
      none: "Żaden",
    },
    search: {
      title: "Szukaj",
      placeholder: "Szukaj...",
      restrict: "Ogranicz do widocznego obszaru",
    },
    layer: {
      title: "Warstwy",
      pin: "Warstwa pinów",
      delete: "Usuń warstwę",
      browse: "Przeglądaj warstwę",
      random: "Pokaż losowy artykuł",
      zoom: "Powiększ, aby pokazać całą warstwę",
      visible: "Pokaż / ukryj warstwę",
      category: "Kategoria",
      search: "Szukaj",
      other: "Inny",
    },
    wikicard: {
      search: "Obszar wyszukiwania",
    }
  },

  ca: {
    controls: {
      show: "Mostra els controls",
      hide: "Amaga els controls",
    },
    articles: {
      title: "Tots els articles",
      tooltip: "Mostra / amaga la capa",
    },
    base: {
      title: "Mapa base",
      map: "Mapa",
      satellite: "Satèl·lit",
      none: "Cap",
    },
    search: {
      title: "Cerca",
      placeholder: "Cerca...",
      restrict: "Restringeix a l'àrea visible",
    },
    layer: {
      title: "Capes",
      pin: "Capa de pins",
      delete: "Suprimeix la capa",
      browse: "Navega per capa",
      random: "Mostra l'article a l'atzar",
      zoom: "Amplieu per mostrar tota la capa",
      visible: "Mostra / amaga la capa",
      category: "Categoria",
      search: "Cerca",
      other: "Altres",
    },
    wikicard: {
      search: "Àrea de cerca",
    }
  },

};
